import { Component, OnInit, Input, OnDestroy, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "app/core/services/layout.service";
import { NavigationService } from "app/core/services/navigation.service";
import { ThemeService } from "app/core/services/theme.service";
import { JwtAuthService } from "app/core/services/auth/jwt-auth.service";
import { MessagingService } from "app/core/services/messaging.service";
import { decodedToken } from "app/core/helpers/token.helper";
import { Router } from "@angular/router";
import { UserService } from "app/core/services/user.service";
import { EmployeeService } from "app/core/services/employee.service";
import { NBFCService } from "app/core/services/nbfc.service";
import { NBFCEmployeeService } from "app/core/services/nbfc-employee.service";
import { GenerateRouteService } from "app/core/services/generate-route.service";
import { PermissionService } from "app/core/services/permission.service";

@Component({
  selector: "app-header-top",
  templateUrl: "./header-top.component.html",
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  show: boolean = true;
  menuItems: any;
  menuItemSub: Subscription;
  tgssThemes: any[] = [];
  currentLang = "en";
  availableLangs = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "Spanish",
      code: "es",
    },
  ];
  @Input() notificPanel;
  notificationCount: any = 0;
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private messagingService: MessagingService,
    private router: Router,
    private userservice: UserService,
    private nbfc: NBFCService,
    private nbfcEmployee: NBFCEmployeeService,
    private employeeService: EmployeeService,
    public permission: PermissionService,
    private generateCountService: GenerateRouteService,
  ) {
    const token = window.localStorage.getItem("NEOLINETOKEN");
    this.messagingService.requestPermission(token);
    //this.messagingService.receiveMsgBackground();
    this.messagingService.receiveMessage();
    this.getNotificationCount();
  }

  // ngOnInit() {
  //   this.layoutConf = this.layout.layoutConf;
  //   this.tgssThemes = this.themeService.tgssThemes;
  //   this.menuItemSub = this.navService.menuItems$
  //   .subscribe(res => {
  //     res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
  //     let limit = 5
  //     let mainItems:any[] = res.slice(0, limit)
  //     if(res.length <= limit) {
  //       return this.menuItems = mainItems
  //     }
  //     let subItems:any[] = res.slice(limit, res.length - 1)
  //     mainItems.push({
  //       name: 'More',
  //       type: 'dropDown',
  //       tooltip: 'More',
  //       icon: 'keyboard_arrow_down',
  //       sub: subItems
  //     })
  //     this.menuItems = mainItems
  //   })
  // }

  ngOnInit() {
    this.getPortal();
    this.employee();
    this.nBFC();
    this.layoutConf = this.layout.layoutConf;
    this.tgssThemes = this.themeService.tgssThemes;
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      res = res.filter(
        (item) => item.type !== "icon" && item.type !== "separator"
      );
      let limit: any;
      if (window.matchMedia(`(max-width: 1025px)`).matches) {
        limit = 1;
      } else if (window.matchMedia(`(max-width: 1280px)`).matches) {
        limit = 2;
      } else if (window.matchMedia(`(max-width: 1366px)`).matches) {
        limit = 3;
      } else if (window.matchMedia(`(max-width: 1466px)`).matches) {
        limit = 3;
      } else if (window.matchMedia(`(max-width: 1536px)`).matches) {
        limit = 4;
      } else if (window.matchMedia(`(min-width: 1536px)`).matches) {
        limit = 5;
      }
      // if (
      //   window.matchMedia(`(max-width: 1270px) and (min-width: 1100px)`).matches
      // ) {
      //   limit = 4;
      // } else if (window.matchMedia(`(max-width: 1280px)`).matches) {
      //   limit = 3;
      // } else if (window.matchMedia(`(max-width: 1025px)`).matches) {
      //   limit = 1;
      // } else if (window.matchMedia(`(max-width: 1281px)`).matches) {
      //   limit = 4;
      // } else if (
      //   window.matchMedia(`(max-width: 1460px) and (min-width: 1282px)`).matches
      // ) {
      //   limit = 4;
      // } else if (
      //   window.matchMedia(`(max-width: 1536px) and (min-width: 1460px)`).matches
      // ) {
      //   limit = 5;
      // } else if (window.matchMedia(`(min-width: 1536px)`).matches) {
      //   limit = 6;
      // }
      // let limit = window.matchMedia(`(max-width: 1372px)`).matches  ? 10 :  window.matchMedia(`(max-width: 1222px)`).matches ? 9 : window.matchMedia(`(max-width: 1142px)`).matches ? 8 : '11';
      let mainItems: any[] = res.slice(0, limit);
      if (res.length <= limit) {
        return (this.menuItems = mainItems);
      }
      let subItems: any[] = res.slice(limit, res.length);

      mainItems.push({
        name: "More",
        type: "dropDown",
        tooltip: "More",
        icon: "keyboard_arrow_down",
        sub: subItems,
      });

      this.menuItems = mainItems;
    });
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }
  setLang() {
    this.translate.use(this.currentLang);
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name });
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  employee() {
    let token = decodedToken();
    if (token.portal == "employee") {
      this.show = false;
    }
  }
  nBFC() {
    let token = decodedToken();
    if (token.portal == "nbfc") {
      this.show = false;
    }
  }

  getPortal() {
    const token = decodedToken();
    // console.log(token)
    return token;
  }

  signout() {
    let token = decodedToken();
    if (token.portal == "employee") {
      this.employeeService.logout();
    } else if (token.portal == "NBFC Authority") {
      this.nbfc.logout();
    } else if (token.portal == "NBFC Employee") {
      this.nbfcEmployee.logout();
    } else {
      this.userservice.logout();
    }
  }

  getNotificationCount() {
    this.generateCountService.queueCount.subscribe({
      next: (count) => {
        console.log(count);
        this.notificationCount = count ? count : 0;
      }
    })
  }

  goToNotification() {
    this.router.navigate(['notification']);
  }

  checkNull(data) {
    return data != '' && data != undefined && data != null
  }
}
